/*
 * @Author: jiang
 * @Date: 2021-06-11 11:20:31
 * @Description: 新闻改成成绩单
 */
import request from '../axios'

/**
 * 获取新闻分类树
 */
export function getNewsCategoryTree(id = '') {
  return request({
    url: `/api/categorys/${id}`,
  })
}

/**
 * 创建新闻分类
 */
export function createNewsCategory(params) {
  return request({
    method: 'post',
    url: '/api/category',
    data: params,
  })
}

/**
 * 修改新闻分类
 */
export function updateNewsCategory(params) {
  return request({
    method: 'post',
    url: `/api/category/${params.id}`,
    data: params,
  })
}

/**
 * 删除新闻分类
 */
export function deleteNewsCategory(id) {
  return request({
    method: 'post',
    url: `/api/category/del/${id}`,
  })
}

/**
 * 获取新闻列表
 * @param { string } [cid] 分类id
 */
export function getNewsPage(id, params = {}) {
  return request({
    url: '/api/posts',
    params,
  })
}
/**
 * 获取新闻列表
 * @param { string } [cid] 分类id
 */
export function getNewsPage2(id, params = {}) {
  return request({
    url: '/api/posts2',
    params,
  })
}
/**
 * 查询部门及子部门的文章
 */
export function getDeptNewsPage(id, params) {
  return request({
    url: `/api/departments/${id}/posts`,
    params,
  })
}

/**
 * 获取当前用户新闻列表
 */
export function getUserNewsPage() {
  return request({
    url: '/api/users/posts',
  })
}

/**
 * 新闻详情
 * @param { string } id
 */
export function getNewsDetail(id) {
  return request({
    url: `/api/posts/${id}`,
  })
}

/**
 * 发布新闻
 */
export function createNews(params) {
  return request({
    method: 'post',
    url: '/api/posts',
    data: params,
  })
}

/**
 * 修改新闻
 */
export function updateNews(id, params) {
  return request({
    method: 'post',
    url: `/api/posts/${id}`,
    data: params,
  })
}

/**
 * 删除新闻
 */
export function deleteNews(id) {
  return request({
    method: 'post',
    url: `/api/posts/del/${id}`,
  })
}

/**
 * 获取当前用户新闻列表
 */
export function getPostsColumn(id=null) {
  return request({
    url: '/api/posts/all/column?parent_id='+id,
  })
}

/**
 * 获取当前用户新闻列表
 */
export function getXHD(id=null) {
  return request({
    url: '/api/leader/bi/count',
  })
}
// 创建pdf
export function createPdfs(params) {
  return request({
    method: 'post',
    url: '/api/departments-data-syncs',
    data: params,
  })
}
// 删除pdf
export function deletePdfs(id) {
  return request({
    method: 'post',
    url: `/api/departments-data-syncs/${id}`,
  })
}
// 修改pdf
export function updatePdfs(id, params) {
  return request({
    method: 'post',
    url: `/api/departments-data-syncs/${id}`,
    data: params,
  })
}