<template>
  <b-section-scroll>
    <div
      slot="header"
      class="header"
    >
      <div class="title">任务情况统计</div>
      <div class="type-box">
        <div
          :class="{'type-item': true, active: activeName === 'user'}"
          @click="activeName = 'user'"
        >人员任务</div>
        <div
          :class="{'type-item': true, active: activeName === 'dept'}"
          @click="activeName = 'dept'"
        >部门任务</div>
        <div
          :class="{'type-item': true, active: activeName === 'all'}"
          @click="activeName = 'all'"
        >厅处室任务</div>
      </div>
    </div>

    <div v-loading="loading">
      <div
        v-if="height == 0"
        class="g-empty"
      ></div>
      <div
        v-else
        ref="chart"
        class="chart-box"
        :style="{height: height + 'px'}"
      ></div>
    </div>
  </b-section-scroll>
</template>

<script>
import { getDeptTask, getUserStatistics, getDeptStatistics } from '@/api/task'
import { stateColorMap } from '@/config/dict'

export default {
  data() {
    return {
      loading: false,
      activeName: 'user',
      chart: null,
      start: 0,
      end: 15,
      total: 0,
      height: 0,
    }
  },
  computed: {
    deptId() {
      return this.$store.getters['user/deptId']
    },
  },
  watch: {
    activeName: {
      handler(active) {
        this.fetchData()
      },
      immediate: true,
    },
  },
  methods: {
    fetchData() {
      this.loading = true
      if (this.activeName === 'user') {
        getUserStatistics(this.deptId)
          .then(res => {
            const a = [] // 已完成
            const b = [] // 进行中
            const c = [] // 已逾期
            res.forEach(item => {
              a.push([item.practicable_tasks_count, item.name])
              b.push([item.progress_tasks_count, item.name])
              c.push([item.overdue_tasks_count, item.name])
            })
            this.height = (res.length || 0) * 40 + 100
            this.update(a, b, c)
          })
          .finally(() => {
            this.loading = false
          })
      } else if (this.activeName === 'dept') {
        getDeptStatistics(this.deptId)
          .then(res => {
            const a = [] // 已完成
            const b = [] // 进行中
            const c = [] // 已逾期
            res.forEach(item => {
              a.push([item.practicable_tasks_count, item.name])
              b.push([item.progress_tasks_count, item.name])
              c.push([item.overdue_tasks_count, item.name])
            })
            this.height = (res.length || 0) * 40 + 100
            this.update(a, b, c)
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        getDeptTask()
          .then(res => {
            const a = []
            const b = []
            const c = []
            this.total = Object.keys(res).length
            Object.keys(res).forEach(key => {
              if (res[key]['已完成']) {
                a.push([res[key]['已完成'].length, key])
              }
              if (res[key]['进行中']) {
                b.push([res[key]['进行中'].length, key])
              }
              if (res[key]['已逾期']) {
                c.push([res[key]['已逾期'].length, key])
              }
            })
            this.height = this.total * 20
            this.update(a, b, c)
            // this.interval()
          })
          .finally(() => {
            this.loading = false
          })
      }
    },

    update(a, b, c) {
      const option = {
        legend: {
          right: 10,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'value',
        },
        yAxis: {
          type: 'category',
        },
        color: ['已完成', '进行中', '已逾期'].map(item => stateColorMap[item]),
        // dataZoom: {
        //   show: false,
        //   type: 'slider',
        //   yAxisIndex: 0,
        //   startValue: this.start,
        //   endValue: this.end,
        // },
        series: [
          {
            name: '已完成',
            type: 'bar',
            stack: 'total',
            label: {
              show: true,
              color: '#ffffff',
            },
            // emphasis: {
            //   focus: 'series',
            // },
            data: a,
          },
          {
            name: '进行中',
            type: 'bar',
            stack: 'total',
            label: {
              show: true,
              color: '#ffffff',
            },
            // emphasis: {
            //   focus: 'series',
            // },
            data: b,
          },
          {
            name: '已逾期',
            type: 'bar',
            stack: 'total',
            label: {
              show: true,
              color: '#ffffff',
            },
            // emphasis: {
            //   focus: 'series',
            // },
            data: c,
          },
        ],
      }
      this.$nextTick(() => {
        if (this.chart) {
          this.chart.dispose()
        }
        console.log(this.height)
        if (this.height === 0) return
        this.chart = this.$echarts.init(this.$refs.chart)
        this.chart.setOption(option, false)
        this.chart.resize({ height: this.height + 'px' })
      })
    },
    // update(a, b, c) {
    //   const option = {
    //     series: [
    //       {
    //         data: a,
    //       },
    //       {
    //         data: b,
    //       },
    //       {
    //         data: c,
    //       },
    //     ],
    //   }
    //   this.chart.setOption(option)
    //   console.log(this.height)
    //   this.chart.resize({ height: this.height + 'px' })
    // },
    interval() {
      if (this.timer) {
        clearInterval(this.timer)
      }
      this.timer = setInterval(() => {
        this.start++
        this.end++
        if (this.end > this.total) {
          this.start = 0
          this.end = 15
        }
        const option = {
          dataZoom: {
            startValue: this.start,
            endValue: this.end,
          },
        }
        this.chart.setOption(option)
      }, 2000)
      this.$once('hook:beforeDestory', () => {
        clearInterval(this.timer)
        this.timer = null
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;

  .type-box {
    display: flex;
    margin-left: auto;

    .type-item {
      padding: 10px;
      font-size: 12px;
      line-height: 1;
      color: #888;

      &:hover,
      &.active {
        color: $color-primary;
        cursor: pointer;
      }
    }
  }
}

.chart-box {
  width: 100%;
  // height: 1000px;
}
</style>
