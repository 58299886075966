<template>
  <b-section-scroll>
    <!-- 头部 -->
    <div
      class="header"
      slot="header"
    >
      <el-tabs
        class="r-nav"
        v-model="activeName"
      >
        <!-- <el-tab-pane
          label="工作日报"
          name="list"
        ></el-tab-pane> -->
        <el-tab-pane
          label="工作日报"
          name="schedule"
        ></el-tab-pane>
      </el-tabs>

      <el-button
        type="text"
        @click="onNav"
      >查看更多</el-button>
    </div>

    <!-- 内容区 -->
    <div v-loading="loading">
      <div class="date-box">
        <b-select-week
          v-if="activeName === 'list'"
          layout="vertical"
          @change="onListDateChange"
        ></b-select-week>
        <el-calendar
          v-if="activeName === 'schedule'"
          v-model="scheduleDate"
          :range="scheduleDateRange"
        >
          <template
            slot="dateCell"
            slot-scope="{data}"
          >
            <div class="num">{{ data.day.split('-')[2] }}</div>
          </template>
        </el-calendar>
      </div>
      <div
        class="user-list"
        v-if="activeName === 'list'"
      >
        <!-- <div
          v-for="item in list"
          :key="item.id"
        >
          <div class="title">本周工作总结</div>
          <div
            class="content"
            v-html="item.week_summary"
          ></div>
          <el-divider></el-divider>
          <div class="title">下周工作计划</div>
          <div
            class="content"
            v-html="item.next_week_plan"
          ></div>
        </div> -->
      </div>
      <div v-if="activeName === 'schedule'">
        <!-- <div
          class="schedule-item"
          v-for="item in list"
          :key="item.id"
        >
          <div class="content g-text-ellipsis">
            {{ item.content }}
          </div>
          <el-popover
            placement="top-end"
            width="260"
            trigger="hover"
            :content="item.content"
          >
            <i
              slot="reference"
              class="el-icon-more"
            ></i>
          </el-popover>
        </div> -->

        <div
          class="report-item"
          v-for="item in list"
          :key="item.id"
        >
          <div class="content">{{ item.content}}</div>
        </div>
      </div>
      <div
        v-if="list.length === 0"
        class="g-empty"
      ></div>

      <b-dialog
        v-model="dialogVisible"
        title="详细信息"
        width="300px"
      >
        {{ dialogContent}}
      </b-dialog>
    </div>
  </b-section-scroll>
</template>

<script>
import BSelectWeek from '@/components/select/week'
// import { getMyScheduleList } from '@/api/manager'
import { getAllReportPage } from '@/api/report/index'
export default {
  components: {
    BSelectWeek,
  },
  data() {
    return {
      activeName: 'schedule',
      loading: false,
      list: [],
      backlogList: [],
      currentList: [],
      currentDate: new Date(),
      listDate: {},
      scheduleDateRange: [],
      scheduleDate: new Date(),
      dialogVisible: false,
      dialogContent: '',
    }
  },
  computed: {
    userId() {
      return this.$store.getters['user/id']
    },
    deptId() {
      return this.$store.getters['user/deptId']
    },
  },
  watch: {
    activeName: {
      handler(active) {
        this.list = []
        if (this.activeName === 'schedule') {
          this.fetchList()
        }
      },
    },
    scheduleDate: {
      handler(active) {
        this.list = []
        this.fetchList()
      },
      immediate: true,
    },
  },
  created() {
    // console.log(this.$tools.dayjs().day())
    let index = this.$tools.dayjs().day()
    if (index === 0) {
      index = 7
    }
    const start = this.$tools
      .dayjs()
      .subtract(index - 1, 'day')
      .format('YYYY-MM-DD')
    const end = this.$tools
      .dayjs()
      .add(7 - index, 'day')
      .format('YYYY-MM-DD')
    this.scheduleDateRange = [start, end]
    console.log(start, end)
  },
  methods: {
    fetchList() {
      this.loading = true
      if (this.activeName === 'list') {
        // const id = this.userId
        // const params = {
        //   start_date: this.listDate.start,
        //   end_date: this.listDate.end,
        // }
        // getUserWork(id, params)
        //   .then(res => {
        //     if (!Array.isArray(res)) {
        //       this.list = [res]
        //     } else {
        //       this.list = res
        //     }
        //   })
        //   .finally(() => {
        //     this.loading = false
        //   })
      } else {
        // const params = {
        //   start_date: this.$tools.dayjs(this.scheduleDate).format('YYYY-MM-DD'),
        //   end_date: this.$tools.dayjs(this.scheduleDate).format('YYYY-MM-DD'),
        // }
        // getMyScheduleList(params)
        //   .then(res => {
        //     this.list = res
        //   })
        //   .finally(() => {
        //     this.loading = false
        //   })

        const params = {
          start_date: this.$tools.dayjs(this.scheduleDate).format('YYYY-MM-DD'),
          end_date: this.$tools.dayjs(this.scheduleDate).format('YYYY-MM-DD'),
          user_id: this.userId,
          page: 1,
        }
        getAllReportPage(params)
          .then(res => {
            this.list = res.data
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    onNav() {
      if (this.activeName === 'list') {
        this.$router.push({ path: '/report/list/index' })
      } else if (this.activeName === 'schedule') {
        this.$router.push({ path: '/manager-user/schedule' })
      }
    },
    onListDateChange(date) {
      this.listDate = {
        end: date.end_date,
        start: date.start_date,
      }
      this.fetchList()
    },
    onCommand(command, row) {
      this.dialogContent = row.content
      this.dialogVisible = true
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
}

.date-box {
  padding: 10px 8px;
  background-color: rgba($color: #dff9f4, $alpha: 0.7);
  border-radius: 4px;

  .el-calendar {
    background: transparent;
  }
}

.user-list {
  .title {
    font-size: 14px;
    font-weight: bold;
    line-height: 32px;
    color: #b6babe;
  }
}

.schedule-item {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 15px;
  margin-top: 15px;
  background: rgba($color: #dfe3e8, $alpha: 0.26);
  border-radius: 10px;

  .content {
    flex: 1;
    min-width: 0;
    margin-right: 20px;
    font-size: 14px;
    font-weight: bold;
    color: #2b2d42;
  }
}

.report-item {
  padding: 15px;
  margin-top: 15px;
  background: rgba($color: #dfe3e8, $alpha: 0.26);
  border-radius: 10px;

  .content {
    min-width: 0;
    font-size: 14px;
    font-weight: bold;
    color: #2b2d42;
    word-break: break-all;
  }
}

/deep/ {
  .el-calendar-table td.is-selected {
    background: transparent;
  }

  .is-selected .num {
    width: 32px;
    height: 32px;
    line-height: 32px;
    color: #fff;
    text-align: center;
    background: #04a37e;
    border-radius: 50%;
  }

  .el-calendar__body {
    padding: 0;
  }

  .el-calendar-table tr td {
    border: none;
  }

  .el-calendar-table .el-calendar-day {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
  }
}
</style>
