/*
 * @Author: jiang
 * @Date: 2021-07-13 14:33:33
 * @Description:
 */
import request from '../axios'

/**
 * 获取月的周信息
 * @param { string } month - 年月（例：2021-7）
 */
export function getMonthWeek(month) {
  return request({
    url: '/api/get/month/weeks',
    params: { month },
  })
}

/**
 * 导出为word
 */
export function exportReport(params) {
  return request({
    url: '/api/export/schedule',
    params,
  })
}

/**
 * 导出为excel
 */
export function exportReportExcel(params) {
  return request({
    url: '/api/user/schedule/export/excel',
    params,
    responseType: 'blob',
  }).then(res => {
    const blobURL = window.URL.createObjectURL(new Blob([res]))
    const tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = blobURL
    tempLink.setAttribute('download', '人员日程数据.xlsx')
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank')
    }
    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
    // 释放blob URL地址
    window.URL.revokeObjectURL(blobURL)
  })
}

/**
 * 分页获取意见列表
 * @param { object } params
 * @param { number } params.department_id
 * @param { string } params.start_date
 * @param { string } params.end_date
 */
export function getReportPage(params) {
  return request({
    url: '/api/user/schedules',
    params,
  })
}

/**
 * 分页领导工作周报
 * @param {*} params
 * @returns
 */
export function getAllReportPage(params) {
  return request({
    url: '/api/schedule',
    params,
  })
}

/**
 * 获取我创建的领导周报信息
 * @param { object } params
 * @param { number } params.page - 页码
 */
export function getMyCreateReportPage(params) {
  return request({
    url: '/api/my/crated/schedule',
    params,
  })
}

/**
 * 周报详情
 * @param { number } id
 */
export function getReport(id) {
  return request({
    url: `/api/schedule/${id}`,
  })
}

/**
 * 新建
 * @param { object } params
 * @param { string } params.department_id
 * @param { string } params.user_id
 * @param { string } params.schedule_date
 * @param { string } params.content
 */
export function createReport(params) {
  return request({
    method: 'post',
    url: '/api/schedule',
    data: params,
  })
}

/**
 * 更新
 * @param { number } id
 * @param { object } params
 * @param { string } params.department_id
 * @param { string } params.user_id
 * @param { string } params.schedule_date
 * @param { string } params.content
 */
export function updateReport(id, params) {
  return request({
    method: 'post',
    url: `/api/schedule/${id}`,
    data: params,
  })
}

/**
 * 删除
 * @param { number } id
 */
export function deleteReport(id) {
  return request({
    method: 'post',
    url: `/api/schedule/del/${id}`,
  })
}
