<template>
  <div class="card-box">
    <div
      class="card-item"
      v-for="item in list"
      :key="item.title"
      :style="{'background-image': 'url(' + item.icon + ')'}"
      @click="$router.push({path: item.path, query: item.query})"
    >
      <div class="title">{{ item.title }}</div>
      <div class="count">{{ item.count }}</div>
    </div>
  </div>
</template>

<script>
import { getNewsPage } from '@/api/news'
import { getBacklogCount } from '@/api/backlog'

export default {
  data() {
    return {
      list: [
        {
          title: '成绩单',
          count: 0,
          icon: require('@/assets/images/card-bg-doing.png'),
          path: '/news',
        },
        {
          title: '马上办',
          count: 0,
          icon: require('@/assets/images/card-bg-done.png'),
          path: '/task/backlog',
          query: {
            status: '进行中',
          },
        },
        {
          title: '已逾期',
          count: 0,
          icon: require('@/assets/images/card-bg-undo.png'),
          path: '/task/backlog',
          query: {
            status: '超时未完成',
          },
        },
        {
          title: '已发布',
          count: 0,
          icon: require('@/assets/images/card-bg-fabu.png'),
          path: '/task',
          query: {
            status: '进行中',
          },
        },
      ],
    }
  },
  computed: {
    userId() {
      return this.$store.getters['user/id']
    },
    deptId() {
      return this.$store.getters['user/deptId']
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      getNewsPage(2, { department_id: this.deptId }).then(res => {
        this.list[0].count = res.total
      })
      getBacklogCount(this.deptId, this.userId).then(res => {
        this.list[1].count = res.departmet_progress_tasks_count + res.user_progress_tasks_count
        this.list[2].count =res.departmet_overdue_no_action_tasks_count + res.user_overdue_no_action_tasks_count
        this.list[3].count =res.user_release_agendas_count
      })

    },
  },
}
</script>
<style lang="scss" scoped>
.card-box {
  display: flex;
  height: 100%;

  .card-item {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    min-width: 0;
    padding-left: 20px;
    font-size: 22px;
    line-height: 1;
    color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 4px;

    + .card-item {
      margin-left: 10px;
    }

    &:hover {
      cursor: pointer;
    }

    .count {
      margin-top: 15px;
      font-size: 44px;
      font-weight: bold;
    }
  }
}
</style>
