<template>
  <b-section-scroll>
    <!-- 头部 -->
    <div
      class="header"
      slot="header"
    >
      <el-tabs
        class="r-nav"
        v-model="activeTab"
      >
        <el-tab-pane
          label="部门马上办"
          name="dept"
        ></el-tab-pane>
        <el-tab-pane
          label="个人马上办"
          name="user"
        ></el-tab-pane>
      </el-tabs>

      <el-button
        type="text"
        @click="onNav"
      >查看更多</el-button>
    </div>

    <!-- 内容区 -->
    <div v-loading="loading">
      <div
        v-if="list.length === 0"
        class="g-empty"
      ></div>
      <div class="backlog-box">
        <div
          class="backlog-item"
          v-for="item in list"
          :key="item.id"
          @click="onNavDetail(item)"
        >
          <div class="title g-text-ellipsis">{{ item.name }}</div>
          <div class="footer">
            <div>
              <span v-if="item.user.department">[{{item.user.department.name}}]</span>
              <span>{{ item.user.name }}</span>
              <span>发布</span>
              <span class="g-mx-5">|</span>
              <span> {{ item.executive_end_data }}</span>
              <span>截止</span>
            </div>
            <div
              class="status"
              :style="{ color: stateColorMap[item.state]}"
            >{{ item.state }}</div>
          </div>
        </div>
      </div>
    </div>
  </b-section-scroll>
</template>

<script>
import { getBacklogPage } from '@/api/backlog'
import { stateColorMap } from '@/config/dict'

export default {
  data() {
    return {
      stateColorMap,
      activeTab: 'dept',
      loading: false,
      list: [],
      backlogList: [],
      currentList: [],
      currentDate: new Date(),
    }
  },
  computed: {
    userId() {
      return this.$store.getters['user/id']
    },
    deptId() {
      return this.$store.getters['user/deptId']
    },
  },
  watch: {
    activeTab: {
      handler(active) {
        this.fetchList()
      },
      immediate: true,
    },
  },
  methods: {
    fetchList() {
      this.loading = true
      const id = this.activeTab === 'dept' ? this.deptId : this.userId
      const params = {
        current_page: 1,
        page: 1,
        current_count: 10,
        state: '进行中,已逾期',
      }
      getBacklogPage(id, params, this.activeTab)
        .then(res => {
          this.list = res.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    onNav() {
      this.$router.push({
        path: '/task/backlog',
        query: {
          active: this.activeTab,
        },
      })
    },
    onNavDetail(row) {
      this.$router.push({
        path: '/task/info',
        query: { id: row.agenda_id, type: 'backlog', backlogId: row.id },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
}

.backlog-box {
  .backlog-item {
    padding: 15px;
    margin-bottom: 10px;
    cursor: pointer;
    background: rgba($color: #dfe3e8, $alpha: 0.26);
    border-radius: 8px;

    &:hover {
      background: rgba($color: #dfe3e8, $alpha: 0.34);
    }

    .title {
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: bold;
      color: #2b2d42;
    }

    .footer {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      color: #747e8f;
    }
  }
}
</style>
